import * as React from 'react';
import { Avatar } from '@mui/material';
import { useAvatarGradient } from '@sinups/agg';

export const UserAvatar = ({ viewer, title = "", height = 50, width = 50, callBack }) => {

  const avatarGradient = useAvatarGradient(viewer?.Id?.toString() ?? '');
  const avatarName = `${viewer?.Surname?.charAt(0)?.toUpperCase() || ""} ${viewer?.FirstName?.charAt(0)?.toUpperCase() || ""}`;

  return (
    <>
    {
        viewer.Avatar ?
        (<Avatar
        sx={{ cursor: 'pointer', height: height, width: width }}
        src={
            viewer.Avatar
            ? `/api/v2/profile/getAvatar?id=${viewer.Id}`
            : null
        }
        title={title}
        onClick={(event) => callBack ? callBack(event) : {}}
        />) :
        (<Avatar
            sx={{ cursor: 'pointer', height: height, width: width, background: avatarGradient }}
            title={title}
            onClick={(event) => callBack ? callBack(event) : {}}
        >{avatarName}</Avatar>)
    }
    </>
  );
};
