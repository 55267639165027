import { store } from 'app/store';
import { wait } from './wait';

import { checkUserTask, userTaskStatuses } from 'entities/userTask';
import { showSnackbar } from 'features/Snackbar';

export const runUserTask = async ({
  taskId,
  errorMsg,
  errorTryoutMsg,
  successMsg
}) => {
  let tryCount = 50;

  const run = async () => {
    try {
      const response = await checkUserTask(taskId);

      if (response.Status === userTaskStatuses.error) {
        store.dispatch(
          showSnackbar({
            type: 'error',
            message: errorMsg ?? 'Ошибка при получении данных'
          })
        );
      }

      if (response.Status === userTaskStatuses.done) {
        tryCount--;

        if (!tryCount) {
          store.dispatch(
            showSnackbar({
              type: 'error',
              message:
                errorTryoutMsg ?? 'Превышен лимит ожидания выполнения задачи'
            })
          );
        }

        return;
      }

      await wait(1000);
      await run();
    } catch {}
  };

  await run();
};
