import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

//import { supportTypes, accessType } from 'shared/constants';
//import { showSnackbar } from 'features/Snackbar';
//import { API_URL } from 'shared/lib/request';
//import { getUserInfo } from 'entities/user';
import * as api from './api';

export const fetchDocumentDirectoryThunk = createAsyncThunk(
  'directory/fetchDocumentDirectory',
  api.documentDirectory
);

// export const fetchDocumentDirectoryThunk = createAsyncThunk(
//   'directory/fetchDocumentDirectory',
//   async (body, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
//     try {
//       const result = await api.documentDirectory(body);
//         const users = await getUserInfo(result[0].Documents.map(x => x.OwnerId));
//         //potentially problematic place if too many files wit different owners
//         result[0].Documents.forEach(x => {
//           const user = users.find(user => user.Id === x.OwnerId);
//           x.UserName = `${user.FirstName} ${user.Surname}`;
//           x.Email = user.Email;
//         });
//         return fulfillWithValue(result);
//     } catch(error) {
//         throw rejectWithValue(error);
//     }
// });

export const getLinkInfoThunk = createAsyncThunk(
  'directory/getLinkInfo',
  async (uid) => await api.getLinkInfo(uid)
);

export const getShareInfoThunk = createAsyncThunk(
  'directory/getShareInfo',
  async ({ entityId, entityType = 'Document' }) =>
    await api.getShareInfo(entityId, entityType)
);

// const getPermissions = (access) => {
//   switch (access) {
//     case accessType.Full:
//     case accessType.Write:
//       return { chat: true, comment: true, edit: true };
//     case accessType.Read:
//       return { chat: true, comment: true, edit: false };
//     case accessType.Comment:
//       return { chat: true, comment: false, edit: false };
//     default:
//       return { chat: false, comment: false, edit: false };
//   }
// };

/*
export const openDocumentThunk = createAsyncThunk(
  'directory/openDocument',
  (
    {
      documentId,
      fileName,
      mimeType,
      access = accessType.Read,
      lifeTime = 'Day'
    },
    { dispatch, getState }
  ) => {
    let user = getState().auth.viewer;
    //if (user.Response) user = user.Response.Data.User;

    api.generateLink(documentId, access, lifeTime).then((result) => {
      const supportedList = Object.values(supportTypes);
      var entity = supportedList.find((x) => x.mimeType === mimeType);
      const settings = getState().auth.settings;
      const documentServerUrl = settings.DocumentServer.Url;
      const documentRequestData = {
        fileType: entity.fileType,
        key: result.Uid + '_' + result.Version,
        title: fileName,
        url: result.Url,
        documentType: entity.documentType,
        mode: access === accessType.Write ? 'edit' : 'view',
        userName: `${user.FirstName} ${user.Surname}`,
        userGroup: user.Group ? user.Group : '',
        userId: user.Id,
        callbackUrl: API_URL + '/v1/Documents/UploadCallback',
        user: JSON.stringify({
          name: `${user.FirstName} ${user.Surname}`,
          group: user.Group ? user.Group : '',
          id: user.Id
        }),
        permissions: JSON.stringify(getPermissions(access)),
        documentServerUrl
      };

      window.open(
        `/document.html?${new URLSearchParams(documentRequestData).toString()}`,
        '_about:blank',
        '',
        true
      );
    });
  }
);

export const openLinkThunk = createAsyncThunk(
  'directory/openLink',
  (
    linkData,
    { dispatch, getState }
  ) => {
    const user = getState().auth.viewer;
    const settings = getState().auth.settings;
    //if (user.Response) user = user.Response.Data.User;

    const supportedList = Object.values(supportTypes);
    const entity = supportedList.find(
      (x) => x.mimeType === linkData.Entity.MimeType
    );
    const documentServerUrl = settings.DocumentServer.Url;

    const documentRequestData = {
      fileType: entity.fileType,
      key: linkData.Link.Uid + '_' + linkData.Link.Version,
      title: linkData.Entity.Description,
      url: API_URL + '/v1/Link?uid=' + linkData.Link.Uid,
      documentType: entity.documentType,
      mode: linkData.Link.AccessType === accessType.Write ? 'edit' : 'view',
      // userName: `${user.FirstName} ${user.Surname}`,
      // userGroup: user.Group ? user.Group : '',
      // userId: user.Id,
      callbackUrl: API_URL + '/v1/Documents/UploadCallback',
      // user: JSON.stringify({
      //   name: `${user.FirstName} ${user.Surname}`,
      //   group: user.Group ? user.Group : '',
      //   id: user.Id
      // }),
      permissions: JSON.stringify(getPermissions(linkData.Link.AccessType)),
      documentServerUrl
    };

    window.open(
      `/document.html?${new URLSearchParams(documentRequestData).toString()}`,
      '_about:blank',
      '',
      true
    );
  }
);

export const downloadThunk = createAsyncThunk(
  'directory/download',
  async ({ id, fileName, type }, { dispatch }) => {
    try {
      const result = await api.download(id);
      //alert(typeof result);
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(new Blob([result], { type }));
      link.download = fileName;
      link.click();
    } catch (error) {
      if (error.status === 404) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: "файл отсутствует на сервере :'("
          })
        );
      }
    }
  }
);
*/

export const toggleFavoriteThunk = createAsyncThunk(
  'directory/toggleFavorite',
  async (
    { directoryId, isFavorite, EntityIds, Type = 'Document' },
    { dispatch }
  ) => {
    await api[!isFavorite ? 'addFavorite' : 'deleteFavorite']({
      EntityIds,
      Type
    });

    dispatch(fetchDocumentDirectoryThunk(directoryId));
  }
);

export const deleteDirectoryThunk = createAsyncThunk(
  'directory/deleteDirectory',
  async ({ directoryId, targetId }, { dispatch }) => {
    await api.deleteDirectory(targetId);
    dispatch(fetchDocumentDirectoryThunk(directoryId));
  }
);

export const deleteDocumentThunk = createAsyncThunk(
  'directory/deleteDocument',
  async ({ directoryId, documentIds }, { dispatch }) => {
    await api.deleteDocument(documentIds);
    dispatch(fetchDocumentDirectoryThunk(directoryId));
  }
);

export const shareDocumentThunk = createAsyncThunk(
  'directory/shareDocumentThunk',
  api.share
);

export const unShareDocumentThunk = createAsyncThunk(
  'directory/unShareDocumentThunk',
  api.unShareDocument
);

export const shareFolderThunk = createAsyncThunk(
  'directory/shareFolderThunk',
  api.shareFolder
);

export const unShareThunk = createAsyncThunk(
  'directory/unShareThunk',
  api.unshare
);

export const renameFolderThunk = createAsyncThunk(
  'directory/renameFolderThunk',
  api.renameFolder
);

export const renameFileThunk = createAsyncThunk(
  'directory/renameFileThunk',
  api.renameFile
);

export const copyDocumentThunk = createAsyncThunk(
  'directory/copyDocumentThunk',
  api.copyDocument
);

export const moveDocumentThunk = createAsyncThunk(
  'directory/moveDocumentThunk',
  api.moveDocument
);

export const copyDirectoryThunk = createAsyncThunk(
  'directory/copyDirectoryThunk',
  api.copyDirectory
);

export const moveDirectoryThunk = createAsyncThunk(
  'directory/moveDirectoryThunk',
  api.moveDirectory
);

const initialState = {
  directory: null,
  directoryStatus: 'initial'
};

export const directorySlice = createSlice({
  name: 'directory',
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(fetchDocumentDirectoryThunk.pending, (state) => {
        state.directoryStatus = 'loading';
      })
      .addCase(fetchDocumentDirectoryThunk.fulfilled, (state, action) => {
        state.directoryStatus = 'success';
        state.directory = action.payload ? action.payload[0] : [];
      })
      .addCase(fetchDocumentDirectoryThunk.rejected, (state) => {
        state.directoryStatus = 'error';
      })
});

export const directoryReducer = directorySlice.reducer;

export const selectDirectory = (state) => state.directory.directory;
export const selectDirectoryStatus = (state) => state.directory.directoryStatus;
